import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";
import { adduserU, refreshTocken } from "../../helpers/api/auth";
import {
  AddUserPayloadI,
  AddUserrI,
  UserRegisterPayload,
} from "../../helpers/interface/Register";

interface UserData {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
  };
  type: string;
}

interface Login {
  payload: {
    loginname: string;
    Password: string;
  };
  type: string;
}

interface RefreshToken {
  payload: {
    Token: string;
    RefreshToken: string;
    Expiration: string;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { loginname, Password },
  type,
}: Login): SagaIterator {
  try {
    const response = yield call(loginApi, { loginname, Password });
    console.log(response);
    const user = response?.data;
    api.setLoggedInUser(user);
    setAuthorization(user["token"], user["companyId"]);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error: any) {
    console.log(error)
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null, null);
  }
}

function* refresh({
  payload: { Token, RefreshToken, Expiration },
  type,
}: RefreshToken): SagaIterator {
  try {
    const response = yield call(refreshTocken, {
      Token,
      RefreshToken,
      Expiration,
    });
    const newuser = response?.data;
    const olduser = api.getLoggedInUser();
    olduser.token = newuser.token;
    olduser.refreshToken = newuser.refreshToken;
    olduser.expiration = newuser.expiration;
    api.setLoggedInUser(olduser);
    setAuthorization(olduser["token"], olduser["companyId"]);
    yield put(authApiResponseSuccess(AuthActionTypes.REFRESH_USER, olduser));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.REFRESH_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null, null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null, null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

// function* signup({
//   payload: {
//     CompanyName,
//     RegistrationNumber,
//     CompanyWebsite,
//     CompanyEmail,
//     CompanyPhone,
//     CompanyAddress,
//     ContactPerson,
//     ShortName,
//     Logo,
//     status,
//     IsGSTRegistered,
//     UserFirstName,
//     UserLastName,
//     Login,
//     Password,
//     Address,
//     IsActive,
//     PhoneNumber,
//     PhoneVerificationStatus,
//     EmailId,
//     EmailVerificationStatus,
//     UserVerificationToken,
//     CompanyVerificationToken,
//   },
// }: UserRegisterPayload): SagaIterator {
//   try {
//     const response = yield call(signupApi, {
//       CompanyName,
//       RegistrationNumber,
//       CompanyWebsite,
//       CompanyEmail,
//       CompanyPhone,
//       CompanyAddress,
//       ContactPerson,
//       ShortName,
//       Logo,
//       status,
//       IsGSTRegistered,
//       UserFirstName,
//       UserLastName,
//       Login,
//       Password,
//       Address,
//       IsActive,
//       PhoneNumber,
//       PhoneVerificationStatus,
//       EmailId,
//       EmailVerificationStatus,
//       UserVerificationToken,
//       CompanyVerificationToken,
//     });
//     const user = response.data;
//     yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
//   } catch (error: any) {
//     yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
//     api.setLoggedInUser(null);
//     setAuthorization(null, null);
//   }
// }

function* signup({
  payload: {
    CompanyName,
    ShortName,
    IsGSTRegistered,
    GSTNumber,
    UserFirstName,
    UserLastName,
    Password,
    Address,
    PhoneNumber,
    PhoneVerificationStatus,
    EmailId,
    UserVerificationToken,
  },
}: UserRegisterPayload): SagaIterator {
  try {
    const response = yield call(signupApi, {
      CompanyName,
    ShortName,
    IsGSTRegistered,
    GSTNumber,
    UserFirstName,
    UserLastName,
    Password,
    Address,
    PhoneNumber,
    PhoneVerificationStatus,
    EmailId,
    UserVerificationToken,
    });
    const user = response.data;
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null, null);
  }
}
function* adduser({
  payload: {
    CompanyID,
    UserFirstName,
    UserLastName,
    Login,
    Password,
    Address,
    IsActive,
    PhoneNumber,
    PhoneVerificationStatus,
  },
}: AddUserPayloadI): SagaIterator {
  try {
    const response = yield call(adduserU, {
      CompanyID,
      UserFirstName,
      UserLastName,
      Login,
      Password,
      Address,
      IsActive,
      PhoneNumber,
      PhoneVerificationStatus,
    });
    const user = response.data;
    yield put(authApiResponseSuccess(AuthActionTypes.ADD_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.ADD_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null, null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchRefreshTockenUser() {
  yield takeEvery(AuthActionTypes.REFRESH_USER, refresh);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchAddUser(): any {
  yield takeEvery(AuthActionTypes.ADD_USER, adduser);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchRefreshTockenUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchAddUser),
  ]);
}

export default authSaga;
