import { useEffect, useState, useMemo } from "react";

import { APICore } from "../helpers/api/apiCore";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { refreshUser } from "../redux/actions";

const useTokenRefresh = () => {
  const api = new APICore();
  const dispatch = useDispatch<AppDispatch>();
  const getTokenExpiryTime = () => {
    // Assuming the expiry time is stored in localStorage in UNIX timestamp format
    const user = api.getLoggedInUser();
    const timer = new Date(user?.expireDate);
    return timer ? parseInt(timer.getTime().toString(), 10) : null;
    };

  useEffect(() => {
    const checkTokenExpiry = () => {
    const expiryTime = getTokenExpiryTime();
    const currentTime = Date.now();
    console.log(expiryTime,currentTime)
    if (expiryTime && expiryTime - currentTime < 60000) {
      tockenRefresh();
      }
    }
      const intervalId = setInterval(checkTokenExpiry, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const tockenRefresh = () => {
    const user = api.getLoggedInUser();
    dispatch(
      refreshUser(user?.token, user?.refreshToken, user?.expireDate)
    );
  };

 
};

export default useTokenRefresh;
