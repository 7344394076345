import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Items from "../pages/items";
const EditPurchase = React.lazy(() => import("../pages/purchase/editPurchase"));
const EditSales = React.lazy(() => import("../pages/sales/editSales"));
const ReturnPurchase = React.lazy(() => import("../pages/purchase/returnPurchase"));
const ReturnSales = React.lazy(() => import("../pages/sales/returnSales"));
const CashBook = React.lazy(() => import("../pages/cashBook"));
const DeliveryEstimation = React.lazy(() => import("../pages/deliveryEstimation"));
const AddNewDeliveryEstimation = React.lazy(() => import("../pages/deliveryEstimation/addNewDeliveryEstimation"));
const ViewDeliveryEstimation = React.lazy(() => import("../pages/deliveryEstimation/viewDeliveryEstimation"));
const DeliveryChallanByDeliveryEstimation = React.lazy(() => import("../pages/deliveryChallan/deliveryChallanByExtimation"));
const ViewSalesReturn = React.lazy(() => import("../pages/sales/saleReturnView"));
const ViewPurchaseReturn = React.lazy(() => import("../pages/purchaseReturnView/purchaseReturnView"));
const ChangePassword = React.lazy(() => import("../pages/other/ChangePassword"));
const CustomersReport = React.lazy(() => import("../pages/reports/customerReports"));
const SuppliersReport = React.lazy(() => import("../pages/reports/suppliersReports"));
const SaleReports = React.lazy(() => import("../pages/reports/saleReports"));
const PurchaseReports = React.lazy(() => import("../pages/reports/purchaseReport"));
const CashbookReports = React.lazy(() => import("../pages/reports/cashbookReport"));
const AddStaff = React.lazy(() => import("../pages/other/AddStaff"));
const Gstr1Reports = React.lazy(() => import("../pages/reports/gstr1Reports"));
const Gstr2Reports = React.lazy(() => import("../pages/reports/gstr2Reports"));
// import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));
const StartFree = React.lazy(() => import("../pages/landing/start_free"));
const PrivacyPolicy = React.lazy(
  () => import("../pages/landing/privacy_policy")
);
const RefundPolicy = React.lazy(() => import("../pages/landing/refund_policy"));
const CancellationPolicy = React.lazy(
  () => import("../pages/landing/cancellation_policy")
);

// dashboard
const Dashboard = React.lazy(
  () => import("../pages/dashboard/")
);

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
// -other
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Profile = React.lazy(() => import("../pages/other/Profile"));
const Activity = React.lazy(() => import("../pages/other/Activity"));
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));

// uikit
const UIElements = React.lazy(() => import("../pages/uikit"));

// widgets
const Widgets = React.lazy(() => import("../pages/widgets/"));




const Customers = React.lazy(() => import("../pages/customers"));
const AddNewCustomers = React.lazy(
  () => import("../pages/customers/addNewCustomers")
);

const UpdateCustomers = React.lazy(
  () => import("../pages/customers/editCustomer")
);
const ViewCustomers = React.lazy(
  () => import("../pages/customers/viewCustomer")
);
const Suppliers = React.lazy(() => import("../pages/suppliers/index"));
const AddNewSuppliers = React.lazy(
  () => import("../pages/suppliers/addNewSuppliers")
);
const EditSuppliers = React.lazy(
  () => import("../pages/suppliers/editSuppliers")
);
const ViewSuppliers = React.lazy(
  () => import("../pages/suppliers/viewSuppliers")
);
const ProductItems = React.lazy(() => import("../pages/items/index"));
const AddNewProducts = React.lazy(() => import("../pages/items/addNewItems"));
const EditItems = React.lazy(() => import("../pages/items/editItems"));
const ViewItems = React.lazy(() => import("../pages/items/viewItems"));
const Expense = React.lazy(() => import("../pages/expense/index"));
const AddNewExpense = React.lazy(
  () => import("../pages/expense/addNewExpense")
);
const ViewExpense = React.lazy(() => import("../pages/expense/viewExpense"));
const Purchase = React.lazy(() => import("../pages/purchase/index"));
const AddNewPurchase = React.lazy(
  () => import("../pages/purchase/addNewPurchase")
);
const ViewPurchase = React.lazy(() => import("../pages/purchase/viewPurchase"));
const Sales = React.lazy(() => import("../pages/sales/index"));
const AddNewSales = React.lazy(() => import("../pages/sales/addNewSales"));
const ViewSales = React.lazy(() => import("../pages/sales/viewSales"));
const DeliveryChallan = React.lazy(
  () => import("../pages/deliveryChallan/index")
);
const AddNewDeliveryChallan = React.lazy(
  () => import("../pages/deliveryChallan/addNewDeliveryChallan")
);

const DeleveryChallanBySales = React.lazy(
  () => import("../pages/deliveryChallan/deleveryChallanBySales")
);
const ViewDeliveryChallan = React.lazy(
  () => import("../pages/deliveryChallan/viewDeliveryChallan")
);
const Quotation = React.lazy(() => import("../pages/quotation/index"));
const AddNewQuotation = React.lazy(
  () => import("../pages/quotation/addNewQuotation")
);
const ViewQuotation = React.lazy(
  () => import("../pages/quotation/viewQuotation")
);
const SalesByDeliveryChallan = React.lazy(
  () => import("../pages/sales/salesByDeliveryChallan")
);

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  element: <Dashboard />,
  route: PrivateRoute,
  // children: [
  //   {
  //     path: "/dashboard",
  //     name: "Ecommerce",
  //     element: <EcommerceDashboard />,
  //     route: PrivateRoute,
  //   },
  //   {
  //     path: "/dashboard/analytics",
  //     name: "Analytics",
  //     element: <AnalyticsDashboard />,
  //     route: PrivateRoute,
  //   },
  // ],
};

// customers
const customersRoutes: RoutesProps = {
  path: "/customers",
  name: "Customers",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <Customers />,
};
const addNewCustomersRoutes: RoutesProps = {
  path: "/add-new-customers",
  name: "Add New Customers",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewCustomers />,
};
const updateCustomersRoutes: RoutesProps = {
  path: "/update-customer/:id",
  name: "Update Customers",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <UpdateCustomers />,
};
const viewCustomersRoutes: RoutesProps = {
  path: "/view-customers/:id",
  name: "View Customers",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewCustomers />,
};

const suppliersRoute: RoutesProps = {
  path: "/suppliers",
  name: "Suppliers",
  header: "Navigation",
  route: PrivateRoute,
  element: <Suppliers />,
};
const addNewSuppliersRoutes: RoutesProps = {
  path: "/add-new-suppliers",
  name: "Add New Suppliers",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewSuppliers />,
};
const viewSuppliersRoutes: RoutesProps = {
  path: "/view-suppliers/:id",
  name: "View Suppliers",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewSuppliers />,
};
const updateSupplierssRoutes: RoutesProps = {
  path: "/update-suppliers/:id",
  name: "Update Suppliers",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <EditSuppliers />,
};
const productItemsRoute: RoutesProps = {
  path: "/product-items",
  name: "Items",
  header: "Navigation",
  route: PrivateRoute,
  element: <ProductItems />,
};
const addNewProductItemsRoute: RoutesProps = {
  path: "/add-new-products",
  name: "Add New Products",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewProducts />,
};
const viewItemsRoutes: RoutesProps = {
  path: "/view-items/:id",
  name: "View Items",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewItems />,
};
const updateItemsRoutes: RoutesProps = {
  path: "/update-items/:id",
  name: "Update Items",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <EditItems />,
};
const expenseRoutes: RoutesProps = {
  path: "/expense",
  name: "Expense",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <Expense />,
};
const addNewExpenseRoutes: RoutesProps = {
  path: "/add-new-expense",
  name: "Add New Expense",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewExpense />,
};
const viewExpenseRoutes: RoutesProps = {
  path: "/view-expense/:id",
  name: "View Expense",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewExpense />,
};
const purchaseRoutes: RoutesProps = {
  path: "/purchase",
  name: "Purchase",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <Purchase />,
};
const addNewPurchaseRoutes: RoutesProps = {
  path: "/add-new-purchase",
  name: "Add New Purchase",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewPurchase />,
};
const PurchaseReturnRoutes: RoutesProps = {
  path: "/return-purchase",
  name: "Return Purchase",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ReturnPurchase />,
};
const viewPurchaseRoutes: RoutesProps = {
  path: "/view-purchase/:id",
  name: "View Purchase",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewPurchase />,
};
const updatePurchaseRoutes: RoutesProps = {
  path: "/update-purchase/:id",
  name: "Update Purchase",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <EditPurchase />,
};
const salesRoutes: RoutesProps = {
  path: "/sales",
  name: "Sales",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <Sales />,
};
const addNewSalesRoutes: RoutesProps = {
  path: "/add-new-sales",
  name: "Add New Sales",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewSales />,
};
const returbSalesRoutes: RoutesProps = {
  path: "/return-sales",
  name: "Return Sales",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ReturnSales />,
};
const viewSalesRoutes: RoutesProps = {
  path: "/view-sales/:id",
  name: "View Sales",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewSales />,
};
const updateSalesRoutes: RoutesProps = {
  path: "/update-sales/:id",
  name: "Update Sales",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <EditSales />,
};
const deliveryChallanRoutes: RoutesProps = {
  path: "/delivery-challan",
  name: "Delivery Challan",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <DeliveryChallan />,
};
const addNewDeliveryChallanRoutes: RoutesProps = {
  path: "/add-new-delivery-challan",
  name: "Add New Delivery Challan",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewDeliveryChallan />,
};

const addDeliveryChallanBySalesRoutes: RoutesProps = {
  path: "/add-delivery-challan-by-sales",
  name: "Add New Delivery Challan",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <DeleveryChallanBySales />,
};
const viewDeliveryChallanRoutes: RoutesProps = {
  path: "/view-delivery-challan/:id",
  name: "View Delivery Challan",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewDeliveryChallan />,
};
const quotationRoutes: RoutesProps = {
  path: "/quotation",
  name: "Quotation",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <Quotation />,
};
const addNewQuotationRoutes: RoutesProps = {
  path: "/add-new-quotation",
  name: "Add New Quotation",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewQuotation />,
};
const viewQuotationRoutes: RoutesProps = {
  path: "/view-quotation",
  name: "View Quotation",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewQuotation />,
};

const cashbookRoutes: RoutesProps = {
  path: "/cashbook",
  name: "CashBook",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <CashBook />,
};
const deliveryEstimationRoutes: RoutesProps = {
  path: "/delivery-estimation",
  name: "Quotation/Estimate",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <DeliveryEstimation />,
};
const addNewDeliveryEstimationRoutes: RoutesProps = {
  path: "/add-new-delivery-estimation",
  name: "Add New Quotation/Estimate",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <AddNewDeliveryEstimation />,
};
const viewDeliveryEstimationRoutes: RoutesProps = {
  path: "/view-delivery-estimation/:id",
  name: "View Quotation/Estimate",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewDeliveryEstimation />,
};
const addSalesByDeliveryChallanRoutes: RoutesProps = {
  path: "/add-sales-by-delivery-challan",
  name: "Add New Sales By Delivery Challan",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <SalesByDeliveryChallan />,
};
const addDeliveryChallanByDeliveryEstimationRoutes: RoutesProps = {
  path: "/add-delivery-challan-by-estimation",
  name: "Add Delivery Challan By Delivery Estimation",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <DeliveryChallanByDeliveryEstimation />,
};
const viewReturnSalesRoutes: RoutesProps = {
  path: "/view-sales-return/:id",
  name: "View Sales Return",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewSalesReturn />,
};
const viewReturnPurchaseRoutes: RoutesProps = {
  path: "/view-purchase-return/:id",
  name: "View Purchase Return",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <ViewPurchaseReturn />,
};

const customerReport: RoutesProps = {
  path: "/customersReport",
  name: "Customers Report",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <CustomersReport />,
};
const suppliersReport: RoutesProps = {
  path: "/suppliers-report",
  name: "Suppliers Report",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <SuppliersReport />,
};
const saleReport: RoutesProps = {
  path: "/sale-report",
  name: "Sales Report",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <SaleReports />,
};

const purchaseReport: RoutesProps = {
  path: "/purchase-report",
  name: "Purchase Report",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <PurchaseReports />,
};

const cashbookReport: RoutesProps = {
  path: "/cashbook-report",
  name: "Cashbook Report",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <CashbookReports />,
};
const gstr1Reports: RoutesProps = {
  path: "/gstr1-report",
  name: "Gstr1 Report",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <Gstr1Reports />,
};
const gstr2Reports: RoutesProps = {
  path: "/gstr2-report",
  name: "Gstr2 Report",
  icon: "airplay",
  header: "Navigation",
  route: PrivateRoute,
  element: <Gstr2Reports />,
};
const appRoutes = [
  customersRoutes,
  addNewCustomersRoutes,
  viewCustomersRoutes,
  suppliersRoute,
  addNewSuppliersRoutes,
  viewSuppliersRoutes,
  productItemsRoute,
  addNewProductItemsRoute,
  updateCustomersRoutes,
  updateSupplierssRoutes,
  viewItemsRoutes,
  expenseRoutes,
  addNewExpenseRoutes,
  viewExpenseRoutes,
  updateItemsRoutes,
  purchaseRoutes,
  addNewPurchaseRoutes,
  PurchaseReturnRoutes,
  viewPurchaseRoutes,
  salesRoutes,
  addNewSalesRoutes,
  viewSalesRoutes,
  deliveryChallanRoutes,
  addNewDeliveryChallanRoutes,
  addDeliveryChallanBySalesRoutes,
  viewDeliveryChallanRoutes,
  updatePurchaseRoutes,
  updateSalesRoutes,
  quotationRoutes,
  addNewQuotationRoutes,
  viewQuotationRoutes,
  returbSalesRoutes,
  cashbookRoutes,
  deliveryEstimationRoutes,
  addNewDeliveryEstimationRoutes,
  viewDeliveryEstimationRoutes,
  addSalesByDeliveryChallanRoutes,
  addDeliveryChallanByDeliveryEstimationRoutes,
  viewReturnSalesRoutes,
  viewReturnPurchaseRoutes,
  customerReport,
  suppliersReport,
  saleReport,
  purchaseReport,
  cashbookReport,
  gstr1Reports,
  gstr2Reports
];

// pages
const extrapagesRoutes: RoutesProps = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      element: <Starter />,
      route: PrivateRoute,
    },
    {
      path: "/pages/profile",
      name: "Profile",
      element: <Profile />,
      route: PrivateRoute,
    },
    {
      path: "/pages/change-password",
      name: "Change Password",
      element: <ChangePassword />,
      route: PrivateRoute,
    },
    {
      path: "/pages/staff-list",
      name: "Staff List",
      element: <AddStaff />,
      route: PrivateRoute,
    },
    {
      path: "/pages/activity",
      name: "Activity",
      element: <Activity />,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      element: <Invoice />,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      element: <Pricing />,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/components",
  name: "Components",
  icon: "package",
  header: "UI Elements",
  children: [
    {
      path: "/components/ui-elements",
      name: "UIElements",
      element: <UIElements />,
      route: PrivateRoute,
    },
    {
      path: "/components/widgets",
      name: "Widgets",
      element: <Widgets />,
      route: PrivateRoute,
    },        
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/pricing",
    name: "Pricing",
    element: <Pricing />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/",
    name: "Root",
    element: <Landing />,
    route: Route,
  },
  {
    path: "/get-it-free/",
    name: "Root",
    element: <StartFree />,
    route: Route,
  },
  {
    path: "/privacy-policy/",
    name: "Root",
    element: <PrivacyPolicy />,
    route: Route,
  },
  {
    path: "/refund-policy/",
    name: "Root",
    element: <RefundPolicy />,
    route: Route,
  },
  {
    path: "/cancellation-policy/",
    name: "Root",
    element: <CancellationPolicy />,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
