// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.REFRESH_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.ADD_USER;
  payload: {} | string;
}

interface UserData {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string,
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (
  loginname: string,
  Password: string
): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { loginname, Password },
});

export const refreshUser = (
  Token: string,
  RefreshToken: string,
  Expiration: string
): AuthActionType => ({
  type: AuthActionTypes.REFRESH_USER,
  payload: { Token, RefreshToken, Expiration },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

// export const signupUser = (
//   CompanyName: string,
//   RegistrationNumber: string,
//   CompanyWebsite: string,
//   CompanyEmail: string,
//   CompanyPhone: string,
//   CompanyAddress: string,
//   ContactPerson: string,
//   ShortName: string,
//   Logo: string,
//   status: boolean,
//   IsGSTRegistered: boolean,
//   UserFirstName: string,
//   UserLastName: string,
//   Login: string,
//   Password: string,
//   Address: string,
//   IsActive: boolean,
//   PhoneNumber: string,
//   PhoneVerificationStatus: boolean,
//   EmailId: string,
//     EmailVerificationStatus: boolean,
//     UserVerificationToken:string,
//     CompanyVerificationToken: string
// ): AuthActionType => ({
//   type: AuthActionTypes.SIGNUP_USER,
//   payload: {
//     CompanyName,
//     RegistrationNumber,
//     CompanyWebsite,
//     CompanyEmail,
//     CompanyPhone,
//     CompanyAddress,
//     ContactPerson,
//     ShortName,
//     Logo,
//     status,
//     IsGSTRegistered,
//     UserFirstName,
//     UserLastName,
//     Login,
//     Password,
//     Address,
//     IsActive,
//     PhoneNumber,
//     PhoneVerificationStatus,
//     EmailId,
//     EmailVerificationStatus,
//     UserVerificationToken,
//     CompanyVerificationToken
//   },
// });


export const signupUser = (
  CompanyName: string,
  ShortName: string,
  IsGSTRegistered: boolean,
  GSTNumber:string,
  UserFirstName: string,
  UserLastName: string,
  Password: string,
  Address: string,
  PhoneNumber: string,
  PhoneVerificationStatus: boolean,
  EmailId: string,
    UserVerificationToken:string,
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: {
    CompanyName,
    ShortName,
    IsGSTRegistered,
    GSTNumber,
    UserFirstName,
    UserLastName,
    Password,
    Address,
    PhoneNumber,
    PhoneVerificationStatus,
    EmailId,
    UserVerificationToken,
  },
});

export const addUsera = (
  CompanyID: string,
  UserFirstName: string,
  UserLastName: string,
  Login: string,
  Password: string,
  Address: string,
  IsActive: boolean,
  PhoneNumber: string,
  PhoneVerificationStatus: boolean
): AuthActionType => ({
  type: AuthActionTypes.ADD_USER,
  payload: {
    CompanyID,
    UserFirstName,
    UserLastName,
    Login,
    Password,
    Address,
    IsActive,
    PhoneNumber,
    PhoneVerificationStatus,
  },
});

export const forgotPassword = (email: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
