import { AddUserrI, RegisterI } from "../interface/Register";
import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { loginname: string; Password: string }) {
  const baseUrl = "Login/login";
  let data = {
    PhoneNumber:params.loginname,
    Password:params.Password
  }
  return api.create(`${baseUrl}`, data);
}

// account
function refreshTocken(params: {
  Token: string;
  RefreshToken: string;
  Expiration: string;
}) {
  console.log("call");
  const baseUrl = "Login/refreshToken";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: RegisterI) {
  const baseUrl = "Login/PostCompany";
  let fdata = {
    CompanyName: params.CompanyName,
    ShortName: params.ShortName,
    IsGSTRegistered: params.IsGSTRegistered,
    GSTNumber:params.GSTNumber,
    UserVerificationToken: params.UserVerificationToken,
    UserDetails: [
      {
        UserFirstName: params.UserFirstName,
        UserLastName: params.UserLastName,
        Password: params.Password,
        Address: params.Address,
        PhoneNumber: params.PhoneNumber,
        PhoneVerificationStatus: params.PhoneVerificationStatus,
        EmailId:params.EmailId,
      },
    ],
  };
  return api.create(`${baseUrl}`, fdata);
}

function adduserU(params: AddUserrI) {
  const baseUrl = "Master/PostUser";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, refreshTocken, adduserU };
